@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2vmin;

  font-size: 16px;
  line-height: 1.5;
  color: rgb(55, 53, 47);
  caret-color: rgb(55, 53, 47);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol';
}

.loadingIcon {
  animation: spinner 0.6s linear infinite;
  display: block;
  width: 24px;
  height: 24px;
  color: rgba(55, 53, 47, 0.4);
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorImage {
  max-width: 100%;
  width: 640px;
}

.errorMessage {
  text-align: center;
}

.footer {
  width: 100%;
  max-width: 1100px;
  margin: auto auto 0;
  padding: 8px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.settings {
  user-select: none;
}

.settings a {
  cursor: pointer;
  font-size: 2em;
  display: inline-flex;
  padding: 0.25em;
  margin-right: 1vw;
  transition: color 250ms ease-out;
}

.settings a:hover {
  transition: color 50ms ease-out;
}
.footer_link{
  cursor: pointer;
  border-radius: 8px;
  padding: 8px 20px;
}

.footer_link:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.toggleDarkMode:hover {
  color: #2795e9;
}

.comments {
  width: 100%;
  margin-top: 2em;
  border-top: 1px solid var(--fg-color-0);
}

@media only screen and (max-width: 566px) {
  .footer {
    flex-direction: column;
  }

  .footer > div {
    margin-top: 1em;
  }

  .footer .settings {
    order: 1;
  }
  .footer .copyright {
    order: 2;
  }
}

.likeTweet:hover {
  background: #f6e3e8;
  color: #e0265e;
}

.retweet:hover {
  color: #19bf64;
  background: #e5f2e8;
}

@media (max-width: 1360px) {
  .githubCorner {
    display: none;
  }
}

.badgerLink {
  position: fixed;
  visibility: visible;
  left: 24px !important;
  bottom: 24px !important;
  z-index: 20;
  display: flex !important;
  padding: 8px 10px !important;
  align-items: center !important;
  border-radius: 8px !important;
  background-color: #fff !important;
  transition: all 200ms ease !important;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  font-size: 16px !important;
  line-height: 1;
  font-weight: 500;
  text-align: left;
  color: #222 !important;
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(60, 66, 87, 0.25),
    0 0 0 0 rgba(60, 66, 87, 0.1), 0 2px 5px 0 rgb(60, 66, 87, 0.1);
}

.badgerImage {
  width: 18px !important;
  height: 18px !important;
  margin-right: 6px !important;
  visibility: visible;
  display: block;
}

.badgerText {
  visibility: visible;
  display: block;
  color: #222 !important;
  font-size: 16px !important;
  line-height: 1;
  font-weight: 500;
}

.badgerLink:hover {
  box-shadow: 1px 0 1px 0 rgba(0, 0, 0, 0.12), 0 0 0 1px rgba(60, 66, 87, 0.1),
    0 3px 9px 0 rgb(60, 66, 87, 0.1), 0 2px 5px 0 rgb(60, 66, 87, 0.1);
}

.badgerLink:hover,
.badgerLink:active {
  outline: 0;
}

.main_footer {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.055);
  padding: 50px 0;
  min-height: 100px;
}



.inner_footer {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  width: 100%;
}

.inner_footer_inline {
  display: flex;
  gap: 30px;
}
.inner_footer .logo img {
  max-width: 200px;
  width: 100%;
  height: auto;
}

.inner_footer_inline .footer_column {
  width: auto;
}

.footer_column_inline ul {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.inner_footer_inline_center {
  justify-content: center;
}

.inner_footer_inline_right {
  justify-content: end;
}
.inner_footer_inline_justify_between {
  justify-content: space-between;
}
.inner_footer_inline_column {
    flex-direction: column;
    align-items: start;
    gap: 15px;
}
.inner_footer_column_center {
  align-items: center;
}
.inner_footer_column_right {
  align-items: end;
}

.inner_footer_inline_column .footer_column_inline ul {
  justify-content: center;
}

.main_footer_text {
  max-width: 320px;
}

@media screen and (min-width:768px) {
  .inner_footer_inline {
    align-items: center;
  }
}

@media screen and (max-width:767px) {
  .inner_footer_inline {
    flex-direction: column;
  }
}